import {
  require
} from './../../utils/http'
//生成二维码

function getCode(data) {
  return require('post', '/api/hr24_ee/tool/v1/produce_qr/', data)
}

function produce_jd_qr(data) {
  return require('post', '/api/hr24_ee/tool/v1/produce_jd_qr/', data)
}

function official_account_qr(data) {
  return require('post', '/api/hr24_ee/share/v1/official_account_qr/', data)
}

//获取简历管理数据
function getResumeManage(data) {

  return require('post', '/api/hr24_ee/tool/v1/resume_management/', data)
}
//简历详情
function getResumeDetails(data) {
  return require('post', '/api/hr24_ee/tool/v1/resume_management_detail/', data)
}
//简历详情 简历备注
function getResumeRemark(data) {
  let obj = {
    resume_id: data.resume_id,
    remarks: data.remarks
  }
  return require('post', '/api/hr24_ee/tool/v1/resume_management_remarks/', obj)
}
//简历详情 简历来源
function getResumeSource(data) {
  let obj = {
    resume_id: data.resume_id,
    source_net: data.source_net
  }
  return require('post', '/api/hr24_ee/tool/v1/resume_management_source/', obj)
}
//简历详情 简历状态
function getResumeStatus(data) {
  let obj = {
    resume_id: data.resume_id,
    status: data.status
  }
  return require('post', '/api/hr24_ee/tool/v1/resume_management_status/', obj)
}
//echarts 数据汇总
function getEchartsA() {
  return require('post', '/api/hr24_ee/tool/v1/online_resume/')

}

function getEchartsB() {
  return require('post', '/api/hr24_ee/tool/v1/liechang_resume/')

}
//简历雷达
function getRadar(data) {
  return require('post', '/api/hr24_ee/tool/v1/radar_list/', data)

}
//简历雷达沟通备注
function changeRadarRemarks(data) {
  return require('post', '/api/hr24_ee/tool/v1/radar_remarks/', data)

}
//简历雷达修改状态
function changeRadarStatus(data) {
  return require('post', '/api/hr24_ee/tool/v1/radar_status/', data)

}

//excal上传简历
function upload_file(data) {
  return require('post', '/api/hr24_ee/tool/v1/excel_create_resume/', data)

}
//简历管理创建简历
function create_resume(data) {
  return require('post', '/api/hr24_ee/tool/v1/create_resume/', data)

}
//添加到面试管理
function setting_interview(data) {
  return require('post', '/api/hr24_ee/tool/v1/setting_interview/', data)

}
//面试管理
function interview_list(data) {
  return require('post', '/api/hr24_ee/tool/v1/interview_list/', data)

}

//在线聊天   -聊天内容
function chatting_detail(data) {
  return require('post', '/api/hr24_ee/tool/v1/chatting_detail/', data)

}
//在线聊天   -聊天联系人列表
function chatting_list(data) {
  return require('post', '/api/hr24_ee/tool/v1/chatting_list/', data)

}
//在线聊天   -是否有新消息的接口
function refresh_chatting_info(data) {
  return require('post', '/api/hr24_ee/tool/v1/refresh_chatting/', data)

}
//在线聊天   导航条是否加小红点
function chat_is_read(data) {
  return require('post', '/api/hr24_ee/tool/v1/chat_is_read/', data)

}

//在线聊天   群聊消息内容
function group_chat_record(data) {
  return require('post', '/api/hr24_ee/share/v1/group_chat_record/', data)
}


function logout(data) {
  return require('post', '/api/hr24_ee/user/v1/logout/', data)
}

//vla 信息列表
function vla_list(data) {
  return require('post', '/api/hr24_ee/tool/v1/vla_list/', data)
}
//vla 详情
function vla_detail(data) {
  return require('post', '/api/hr24_ee/tool/v1/vla_detail/', data)
}
//vla 沟通备注
function vla_remarks(data) {
  return require('post', '/api/hr24_ee/tool/v1/vla_remarks/', data)
}
//vla  状态
function vla_status(data) {
  return require('post', '/api/hr24_ee/tool/v1/vla_status/', data)
}

//聊天列表
function chat_list_record(data) {
  return require('post', '/api/hr24_ee/tool/v1/chat_list_record/', data)
}
//聊天列表
function chat_record(data) {
  return require('post', '/api/hr24_ee/tool/v1/chat_record/', data)
}
//查找房间名
function get_roomname(data) {
  return require('post', '/api/hr24_ee/tool/v1/get_roomname/', data)
}

// 企业设置 简历雷达 简历标签接口
function set_clue_tags(data) {
  return require('post', '/api/hr24_ee/tool/v1/set_clue_tags/', data)
}


export {
  getCode,
  getResumeManage,
  vla_list,
  vla_detail,
  getEchartsA,
  getEchartsB,
  getResumeDetails,
  getResumeRemark,
  getResumeSource,
  getResumeStatus,
  getRadar,
  changeRadarRemarks,
  changeRadarStatus,
  upload_file,
  create_resume,
  produce_jd_qr,
  setting_interview,
  interview_list,
  chatting_detail,
  chatting_list,
  refresh_chatting_info,
  chat_is_read,
  logout,
  vla_remarks,
  vla_status,
  group_chat_record,
  official_account_qr,
  chat_list_record,
  chat_record,
  get_roomname,
  set_clue_tags
}