<!-- 生成二维码 -->
<template>
  <div class="wrap">
    <div class="img-canvas" v-loading="loading">
      <div class="canvas_item">
        <canvas ref="shareResume"></canvas>
        <span
          data-clipboard-action="copy"
          class="cobyOrderSn"
          :data-clipboard-text="code_b"
          @click="copyLink"
          v-if="!is_show"
        >点击复制填写简历二维码链接</span>
      </div>
      <div class="canvas_item">
        <canvas ref="addResume"></canvas>
      </div>
      <div class="canvas_item padding" v-if="is_show">
        <span
          data-clipboard-action="copy"
          class="cobyOrderSn"
          :data-clipboard-text="code_b"
          @click="copyLink"
        >点击复制填写简历二维码链接</span>
        <span
          data-clipboard-action="copy"
          class="copyCity"
          :data-clipboard-text="code_city"
          @click="copyCity"
        >点击复制城市精英版二维码链接</span>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { getCode } from '@/api/tool/index'
// import Clipboard from 'clipboard'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      code_b: '',
      code_city: '',
      is_show: false,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //复制城市二为嘛
    copyCity() {
      let _this = this
      let clipboard = new this.$clipboard('.copyCity')
      clipboard.on('success', function () {
        _this.$message.success('复制成功')
      })
      console.log()
    },
    //复制二维码连接
    copyLink() {
      let _this = this
      let clipboard = new this.$clipboard('.cobyOrderSn')
      clipboard.on('success', function () {
        _this.$message.success('复制成功')
      })
      clipboard.on('error', function () {
        _this.$message.success('复制失败')
      })
    },

    //canvas
    createdImg(dom, bgImageUrl, codeUrl) {
      var arr = []
      arr.push(this.loadImg(bgImageUrl))
      arr.push(this.loadImg(codeUrl))

      var that = this
      Promise.all(arr).then(function (arr) {
        console.log(arr)
        that.draw(dom, arr)
      })
    },
    //promise
    loadImg(src) {
      return new Promise((resolve, reject) => {
        var img = new Image()
        img.src = src
        img.onload = function () {
          //加载成功
          resolve(img)
        }
        img.onerror = function () {
          //加载失败
          reject('加载错误')
        }
      })
    },
    //绘制canvas
    draw(dom, arr) {
      var canvas = this.$refs[dom]
      let sum = 4
      canvas.width = 300 * sum
      canvas.height = 500 * sum
      var ctx = canvas.getContext('2d')
      ctx.drawImage(arr[0], 0, 0, 300 * sum, 500 * sum)
      ctx.drawImage(arr[1], 75 * sum, 250 * sum, 150 * sum, 150 * sum)
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.is_show = sessionStorage.getItem('is_hotline') == '0' ? false : true
    getCode({ is_refresh: 'yes' }).then((res) => {
      if (res.code === 0) {
        this.loading = false
        this.createdImg(
          'shareResume',
          require('./../../assets/images/tool/QRJde.png'),
          res.data.company_qr_b
        )
        this.code_b = res.data.company_qr_b
        this.createdImg(
          'addResume',
          require('./../../assets/images/tool/QRJd.png'),
          res.data.company_qr_a
        )
        if (this.is_show) {
          this.code_city = res.data.company_qr_c
        }
      }
    })
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    // this.$nextTick(() => {
    //   this.createdImg(
    //     'addResume',
    //     require('./../../assets/images/tool/QRJd.png')
    //   )
    //   this.createdImg(
    //     'shareResume',
    //     require('./../../assets/images/tool/QRJd.png')
    //   )
    // })
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  @include padding;
}
.img-canvas {
  width: 1000px;
  margin: 50px auto 0;
  display: flex;
  justify-content: space-between;
  // background-color: red;
  min-height: 400px;
}
.canvas_item {
  width: 50%;
}
.canvas_item > canvas {
  display: block;
  width: 300px;
  margin: auto;
}
.canvas_item > .cobyOrderSn,
.canvas_item > .copyCity {
  display: block;
  width: 250px;
  margin: 20px auto;
  background: #ff9900;
  outline: none;
  border: none;
  padding: 6px 6px;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: aliceblue;
}
.canvas_item > .cobyOrderSn:hover {
  background: #ffae35;
  outline: none;
  // border: 2px solid #f1b760;
}
.canvas_item > .copyCity:hover {
  background: #ffae35;
  outline: none;
  // border: 2px solid #f1b760;
}
.padding {
  padding-top: 50px;
}
</style>
